import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

//previous body image component before tryin the alt name fix
// const BodyImage = ({ image, className }) => {
//   const imageData = getImage(image?.localFile);
//
//   if (!imageData) return null;
//
//   return (
//     <div className={`${className ? className : ""}`}>
//       <GatsbyImage alt={image.altText} image={imageData} />
//     </div>
//   );
// };

//tryin to fix the missing alt text for images
const BodyImage = ({ image, className }) => {
  const imageData = getImage(image?.localFile);
  if (!imageData) return null;
  // Accessing fallback.src in gatsbyImageData for the image URL
  const imageUrl = image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
  if (!imageUrl) {
    return null; // Handle the case where the image URL is missing
  }
  // Extracting the file name from the image URL
  const fileName = imageUrl.split("/").pop();
  // Removing the file extension to get just the name (optional)
  const imageName = fileName.split(".")[0];
  // Check if alt text is empty and handle possible undefined localFile
  const altText = image?.altText || (image?.title ? `${image.title}~MyHealthInsurance.com` : `${imageName}_MyHealthInsurance.com`);

  return (
      <div className={`${className ? className : ""}`}>
        <GatsbyImage alt={altText} image={imageData} />
      </div>
  );
};
//end of fix

export default BodyImage;
